import { template as template_4c98b6630a0b4d56a7b8b15fe209ee35 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4c98b6630a0b4d56a7b8b15fe209ee35(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
