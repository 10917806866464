import { template as template_20265ef7288e4e1a919225a6b8937e43 } from "@ember/template-compiler";
const FKText = template_20265ef7288e4e1a919225a6b8937e43(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
